// $primary-color: #6b46c1;

// body {
//   font-family: "Arial", sans-serif;
//   background: linear-gradient(to bottom, $primary-color, #7c3aed);
//   color: #fff;
// }

.card {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.card.isDragging {
  transform: scale(1.1);
  opacity: 0.5;
  z-index: 1000;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scale-up-animation {
  animation: scaleUp 0.5s ease-out forwards;
}