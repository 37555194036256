/* src/components/Loader.css */
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #55018d; /* Фон загрузочного экрана */
  }
  
  .spinner {
    border: 8px solid #3a0049; /* Светло-серый */
    border-top: 8px solid #160035; /* Синий */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  