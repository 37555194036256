@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@keyframes fadeUpAndOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  20% { transform: rotate(-7deg); }
  40% { transform: rotate(7deg); }
  60% { transform: rotate(-7deg); }
  80% { transform: rotate(7deg); }
  100% { transform: rotate(0deg); }
}


body {
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}

body, html, #root {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Card flip animation */
.card {
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.3s;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transform-style: preserve-3d;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
}

.card-front {
  width: 51px;
  height: 75px;
}

.card-back {
  transform: rotateY(180deg);
}